export const MetaTagDetails = [
  {
    url: "/categories/bags-luggage",
    title: "Corporate Gifting | Bags & Luggage | Ekmatra Technology",
    description:
      "We have wide range of bags & luggage as corporate gifting. By gifting these bags you will get traction of your employees, clients or customers.",
  },

  {
    url: "/categories/electronics",
    title: "Electronic Items For Corporate Gifts | Corporate Electronic Gifts",
    description:
      "Buy corporate electronic gifts at the best price. We have electronic appliances for corporate gifting that can be customized to make the gifting very special.",
  },

  {
    url: "/categories/office-stationary",
    title: "Buy Office Supply & Stationary | Best Corporate Gift | Ekmatra",
    description:
      "Shop for office stationary & supplies on at best price. Ek Matra is India's leading one stop shop platform for corporate & customized gifting. ",
  },

  {
    url: "/categories/apparels",
    title: "Corporate Apparel Gift | Customize Gift For Employees & Clients ",
    description:
      "Check out our unique apparel gift section for your very best occasions. Get the best price for your apparels gifts only on Ek Matra. Order Now",
  },

  {
    url: "/categories/grow_kits",
    title: "Unique & Exotic Grow Kits As Corporate Gift By Ek Mattra",
    description:
      "Grow kit is a great addition as a gift for birthday parties, corporate events. These eco-friendly corporate gift is a perfect gift as a appreciation.",
  },

  {
    url: "/categories/edible_gifts",
    title: "Unique & Exotic Grow Kits As Corporate Gift By Ek Mattra",
    description:
      "Grow kit is a great addition as a gift for birthday parties, corporate events. These eco-friendly corporate gift is a perfect gift as a appreciation.",
  },

  {
    url: "/categories/pen_drive",
    title: "Buy Pendrive Online | Corporate Gift Item | Ek Matra",
    description:
      "Custom pen drive also make a great persona corporate gifting option on different occasions. ",
  },
];
