import { ApiHandlerService } from "./shared/services/api-handler.service";
import { Component } from "@angular/core";
import { API } from "app/shared/constants/endpoints";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { filter } from "rxjs/operators";
import { Meta, Title } from "@angular/platform-browser";
import { MetaTagDetails } from "./shared/constants/MetaTagDetails";
import { data } from "jquery";
declare var gtag;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  metaUrl: any;
  metaUrlData: { url: string; title: string; description: string };
  constructor(
    private router: Router,
    private _api: ApiHandlerService,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private metaService: Meta
  ) {}

  ngOnInit() {
    // this.router.events
    //   .pipe(filter((event) => event instanceof NavigationEnd))
    //   .subscribe(() => {
    //     const rt = this.getChild(this.activatedRoute);
    //     rt.data.subscribe((data) => {
    //       console.log(data);
    //       this.titleService.setTitle(data.title);
    //       this.metaService.updateTag(data.description);
    //       alert(data.description);
    //     });
    //   });
    // MetaTagDetails.forEach((element) => {
    //   this.metaUrl = element;
    // });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))

      .subscribe((event: NavigationEnd) => {
        gtag("config", "G-K0KXNK2CYC", {
          page_path: event.urlAfterRedirects,
        });
        this.metaUrl = MetaTagDetails.find(
          (url) => url.url === event.urlAfterRedirects
        );
        if (this.metaUrl?.url === event.urlAfterRedirects) {
          this.titleService.setTitle(this.metaUrl.title);
          this.metaService.updateTag({ name: 'description', content: this.metaUrl.description })
        } else {
          const rt = this.getChild(this.activatedRoute);
          rt.data.subscribe((data) => {
            console.log(data);
            this.titleService.setTitle(data.title);
            this.metaService.updateTag({ name: 'description', content: data.description });
          });
        }
      });
    this.checkToken();
  }

  getChild(activatedRoute: ActivatedRoute) {
    if (activatedRoute.firstChild) {
      return this.getChild(activatedRoute.firstChild);
    } else {
      return activatedRoute;
    }
  }

  async checkToken() {
    let token = await localStorage.getItem("userData");
    if (token) {
      this.getKycDetails2();
    }
  }

  getKycDetails2() {
    this._api.apiGet(API.QUEST_ENDPOINTS.GET_KYC_DETAILS).subscribe(
      (res) => {
        console.log("respone all data", res);

        console.log(res.data);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  // getKycDetails(){
  //   this._api.apiGet(API.QUEST_ENDPOINTS.GET_KYC_DETAILS).pipe(
  //     tap({
  //       next:res=>{
  //         debugger;
  //         console.log(res,"resresres");
  //           // console.log(res.data, "gyc method called............" );
  //           localStorage.setItem('kyc',JSON.stringify(res.data));
  //       },
  //       error:err=> {
  //         console.log("erro,,,,,,,,,,",err);
  //       }
  //     })
  //   ).subscribe()
  // }
}
